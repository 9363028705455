var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Импорт РРЦ",
      "width": 420,
      "actions": _vm.actions
    }
  }, [_c('p', {
    staticClass: "modal-text"
  }, [_vm._v(" Загрузите XLS или CSV файл с рекомендуемыми ценами товаров "), _c('br'), _c('span', {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(" Для корректного импорта рекомендуемых цен "), _c('br'), _vm._v(" подготовьте файл в нужном формате ")]), _c('br'), _c('a', {
    staticClass: "link",
    attrs: {
      "href": "/SalesFinder_RRC_Import_Example.xlsx",
      "download": ""
    }
  }, [_vm._v(" скачать пример файла ")])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('Import-btn2', {
    attrs: {
      "tooltip": "Обратите внимание на правильный формат файла для загрузки. Скачайте готовый пример и заполните его своими SKU и РРЦ",
      "tooltip-classes": "top left",
      "btn-text": "Загрузить список товаров и РРЦ"
    },
    on: {
      "imported": _vm.ImportSkuRrc
    }
  })], 1), _vm.valid ? _c('p', {
    staticClass: "modal-text"
  }, [_c('span', [_vm._v(" Файл добавлен, для загрузки товаров и РРЦ в список нажмите “Импортировать” ")])]) : _vm._e(), _vm.loadingStarted && !_vm.badRrcXlsFormat ? _c('div', {
    staticClass: "modal-text__item"
  }, [_vm._v(" " + _vm._s(_vm.loadingStarted) + " ")]) : _vm._e(), _vm.badRrcXlsFormat ? _c('div', {
    staticClass: "modal-text__item red"
  }, [_vm._v(" " + _vm._s(_vm.badRrcXlsFormat) + " ")]) : _vm.res1 || _vm.res2 ? _c('div', {
    staticClass: "modal-text__item"
  }, [_c('span', [_vm._v("Статистика загрузки :")]), _c('br'), _vm.res1 && _vm.res1.totSku2Add && _vm.res1.totSku2Add > 0 ? _c('span', [_vm._v(" " + _vm._s(this.res1.totSku2Add) + " sku импортировано ")]) : _vm._e(), _c('br'), _vm.res1 && _vm.res1.totDupSku && _vm.res1.totDupSku > 0 ? _c('span', [_vm._v(" " + _vm._s(_vm.plural_totDupSku) + " ")]) : _vm._e(), _c('br'), _vm.res1 && _vm.res1.totAddListSku && _vm.res1.totAddListSku > 0 ? _c('span', [_vm._v(" " + _vm._s(this.res1.totAddListSku) + " sku добавлены в список ")]) : _vm._e(), _c('br'), _vm.res2 && _vm.res2.totNewSku_End && _vm.res2.totNewSku_End > 0 ? _c('span', [_vm._v(" " + _vm._s(this.res2.totNewSku_End) + " sku осталось добавить ")]) : _vm._e()]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };