var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.list ? _c('div', {
    staticClass: "list-page"
  }, [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "report-settings"
        }, [_vm.limitRrc ? _c('div', {
          staticStyle: {
            "margin-top": "10px"
          }
        }, [_c('span', {
          staticClass: "limit"
        }, [_vm._v("Обновлений цен за сегодня:")]), _c('span', {
          staticClass: "limit"
        }, [_vm._v(" " + _vm._s(_vm.limitRrc.count) + " из " + _vm._s(_vm.limitRrc.limit) + " доступных ")])]) : _vm._e()])];
      },
      proxy: true
    }], null, false, 3148542013)
  }, [_c('div', {
    staticClass: "list-page__title-block"
  }, [_c('router-link', {
    staticClass: "list-page__title-link",
    attrs: {
      "to": {
        name: 'retail_price_monitoring/list'
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "19",
      "height": "16",
      "viewBox": "0 0 19 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M18.9999 6.99997H4.41394L9.70694 1.70697L8.29294 0.292969L0.585938 7.99997L8.29294 15.707L9.70694 14.293L4.41394 8.99997H18.9999V6.99997Z",
      "fill": "#316D92",
      "fill-opacity": "0.9"
    }
  })])]), _c('span', [_vm._v(" " + _vm._s(_vm.list.title) + " ")])], 1)]), _c('div', {
    staticClass: "btns-wrap"
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('button', {
    staticClass: "action-button btn-blue btn-settings",
    staticStyle: {
      "margin-right": "20px"
    },
    attrs: {
      "disabled": _vm.loadingPriceVarFresh
    },
    on: {
      "click": _vm.createPriceVarRefreshRequest_btn
    }
  }, [_vm.loadingPriceVarFresh && _vm.totSkuNotPrice != -1 ? _c('div', {
    staticClass: "loading-overlay"
  }, [_c('loading-spinner', {
    staticClass: "spinner"
  })], 1) : _vm._e(), _vm.loadingPriceVarFresh && _vm.totSkuNotPrice != -1 ? _c('span', {
    staticClass: "loading-overlay",
    staticStyle: {
      "padding-left": "10px"
    }
  }, [_c('span', [_vm._v("Осталось еще " + _vm._s(_vm.totSkuNotPrice) + " sku")])]) : _c('span', [_vm._v(" Обновить все цены ")])]), _c('button', {
    staticClass: "btn-outline btn-width-auto",
    on: {
      "click": _vm.onImportRrcButton
    }
  }, [_vm._v(" Импорт товаров с РРЦ ")])])]), _c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs_tables,
      "route-param": "tab"
    },
    model: {
      value: _vm.tabs_tables_model,
      callback: function ($$v) {
        _vm.tabs_tables_model = $$v;
      },
      expression: "tabs_tables_model"
    }
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1), _c('div', {
    staticClass: "list-page__table mt32"
  }, [_vm.reportSettings ? _c(_vm.getTableComponent(_vm.tabs_tables_model.id), {
    key: `table:${_vm.listKey}`,
    ref: "table",
    tag: "component",
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "route-params": _vm.routeParams,
      "list": _vm.list
    },
    on: {
      "selectAction": _vm.onRemove,
      "selectAction_RrcPriceFresh": _vm.onSelectAction_RrcPriceFresh
    }
  }) : _vm._e()], 1), _c('modal-price-var-import', {
    ref: "modalPriceVarImport",
    on: {
      "complete": _vm.onImportComplete
    }
  }), _c('modal-price-var-remove-item', {
    ref: "modalPriceVarRemoveItem"
  }), _c('modal-rrc-no-fresh', {
    ref: "ModalRrcNoFresh",
    attrs: {
      "shedule": _vm.list.shedule
    }
  }), _c('modal-import-rrc', {
    ref: "ModalImportRrc",
    attrs: {
      "id": _vm.list.id
    },
    on: {
      "complete": _vm.onComplete_ModalImportRrc
    }
  }), _c('modal-import-rrc-result', {
    ref: "ModalImportRrcResult",
    on: {
      "complete": _vm.onComplete_ModalImportRrcResult
    }
  })], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };