var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', _vm._b({
    ref: "table",
    attrs: {
      "table-id": "priceVarProducts",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "noData": _vm.noData,
      "item-id": "sku",
      "select-action": "rrcPriceFresh",
      "file-name": _vm.fileName
    },
    on: {
      "selectAction": function ($event) {
        return _vm.$emit('selectAction', $event);
      },
      "selectAction_RrcPriceFresh": function ($event) {
        return _vm.$emit('selectAction_RrcPriceFresh', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "price_fresh",
      fn: function ({
        item
      }) {
        return [item['price_fresh'] == -131313 ? _c('span', {
          staticClass: "gray"
        }, [_vm._v(" нет в наличии ")]) : item['price_fresh'] === -1 ? _c('span', {
          staticClass: "gray"
        }, [_vm._v(" — ")]) : item['_css_' + 'price_fresh'] != '' ? _c('span', {
          class: item['_css_' + 'price_fresh']
        }, [_vm._v(" " + _vm._s(item['price_fresh']) + " ₽ ")]) : _c('span', [_vm._v(" " + _vm._s(item['price_fresh']) + " ₽ ")])];
      }
    }, {
      key: "card_price_fresh",
      fn: function ({
        item
      }) {
        return [item['card_price_fresh'] == -131313 ? _c('span', {
          staticClass: "gray"
        }, [_vm._v(" нет в наличии ")]) : item['card_price_fresh'] === -1 ? _c('span', {
          staticClass: "gray"
        }, [_vm._v(" — ")]) : item['_css_' + 'card_price_fresh'] != '' ? _c('span', {
          class: item['_css_' + 'card_price_fresh']
        }, [_vm._v(" " + _vm._s(item['card_price_fresh']) + " ₽ ")]) : _c('span', [_vm._v(" " + _vm._s(item['card_price_fresh']) + " ₽ ")])];
      }
    }])
  }, 'data-table', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };