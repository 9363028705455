/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ModalLimitReachedRrc.vue?vue&type=template&id=168fe85d&scoped=true"
import script from "./ModalLimitReachedRrc.vue?vue&type=script&lang=js"
export * from "./ModalLimitReachedRrc.vue?vue&type=script&lang=js"
import style0 from "./ModalLimitReachedRrc.vue?vue&type=style&index=0&id=168fe85d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "168fe85d",
  null
  
)

export default component.exports