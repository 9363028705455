import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
////import { getEntityTableColumns } from "@/utils/enums";
//import { dateRangeDaysArray, dateFormat } from "@/utils/dates";

export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    list: {
      type: Object,
      required: true
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      //console.log('tableLoader(): query=', query)

      return this.$store.dispatch("priceVar/getPriceVarProducts", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    noData() {
      return `<center>Список товаров пуст.<br>
                        Для наполнения списка воспользуйтесь кнопкой <b>Импорт товаров с РРЦ</b>
                    </center>`;
    },
    fileName() {
      return `${this.routeParams.mp.toUpperCase()} Cписок ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    columns() {
      /*
      let days = dateRangeDaysArray(
          this.reportSettings.date,
          this.reportSettings.date2
      ).reverse();
      */

      let col = [{
        title: "Toвар",
        // position: pos++,
        // show: true,
        // sort: true,
        name: "title",
        width: 380,
        filter: "text",
        type: "link",
        noOverflow: true,
        getImage: item => item.image,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                ...this.routeParams,
                p_id: item.p_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.title,
            target: "_blank"
          };
        }
      }, {
        title: "SKU",
        // position: 2,
        // show: true,
        // sort: true,
        //name: "sku",
        name: "p_id",
        width: 100,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            href: item.mp_url,
            //text: item.sku,
            text: item.p_id
          };
        }
      },
      /* //!2024-08-21: игнорируем seller, brand
      {
          title: "Бренд",
          position: 3,
          show: true,
          name: "brand",
          width: 100,
          filter: "select",
          type: "link",
          getLink: (item) => {
              return {
                  route: {
                      name: "BrandInfo",
                      params: {
                          b_id: item.b_id,
                          mp: this.routeParams.mp,
                      },
                      query: {
                          date: this.reportSettings.date,
                          date2: this.reportSettings.date2,
                          fbs: this.reportSettings.fbs,
                      },
                  },
                  text: item.brand,
                  target: "_blank",
              };
          },
      },
      {
          title: "Продавец",
          position: 4,
          show: true,
          name: "seller",
          width: 200,
          filter: "select",
          type: "link",
          getLink: (item) => {
              return {
                  route: {
                      name: "SellerInfo",
                      params: {
                          s_id: item.s_id,
                          mp: this.routeParams.mp,
                      },
                      query: {
                          date: this.reportSettings.date,
                          date2: this.reportSettings.date2,
                          fbs: this.reportSettings.fbs,
                      },
                  },
                  text: item.seller,
                  target: "_blank",
              };
          },
      },
      **/

      {
        title: "Рекомендованная Цена",
        // position: 5,
        // show: true,
        // sort: true,
        name: "rrc_price",
        type: "money",
        width: 160,
        filter: "numberRange"
      }

      //...getEntityTableColumns("product", 3),
      /*
      {
          title: "Впервые",
          show: true,
          name: "date",
          width: 100,
          filter: "dateRange",
          type: "date",
      },
      {
          title: "Последний раз",
          show: true,
          name: "date2",
          width: 110,
          filter: "dateRange",
          type: "date",
      },
      */];

      //if( this.reportSettings.qWithPriceFresh ){
      col = col.concat([{
        title: this.list.mp == 'wb' ? "Последняя цена с WB кошельком" : "Последняя цена с Ozon Картой",
        // position: 6,
        // show: true,
        // sort: true,
        name: "card_price_fresh",
        //type: "money",
        type: "slot",
        width: 160,
        filter: "numberRange"
      }, {
        //title: "Разница цен, руб",
        title: "Разница цен " + (this.list.mp == 'wb' ? "с WB кошельком" : "с Ozon Картой") + ", руб",
        // position: 7,
        // show: true,
        //sort: true,
        name: "card_price_var_rub",
        type: "number",
        width: 160,
        filter: "numberRange"
      }, {
        //title: "Разница цен, %",
        title: "Разница цен " + (this.list.mp == 'wb' ? "с WB кошельком" : "с Ozon Картой") + ", %",
        // position: 8,
        // show: true,
        //sort: true,
        name: "card_price_var_percent",
        type: "percent",
        width: 160,
        filter: "numberRange"
      }, {
        title: "Последняя цена",
        //position: 9,
        //show: true,
        //sort: true,
        name: "price_fresh",
        type: "slot",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Разница цен, руб",
        //position: 7,
        //show: true,
        //sort: true,
        name: "price_var_rub",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Разница цен, %",
        //position: 8,
        //show: true,
        //sort: true,
        name: "price_var_percent",
        type: "percent",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Дата обновления",
        //position: 9,
        //show: true,
        //sort: true,
        name: "date_fresh",
        type: "text",
        width: 100
        //filter: "numberRange",
      }]);
      //}

      /*
      let position = col.length
      col = col.concat(
      ...days.map((date) => {
          return {
              title: dateFormat(date),
              position: ++position,
              show: true,
              name: date,
              width: 100,
              type: "number",
              filter: "numberRange",
              noSettings: true,
          }
      }))
      */

      return col.map((col, i) => {
        col.position = i + 1;
        col.show = true;
        col.sort = true;
        return col;
      });
    }
  }
};