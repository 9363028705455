var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": _vm.title,
      "width": 420,
      "actions": _vm.actions
    }
  }, [_c('div', {
    staticClass: "modal-text"
  }, [_c('div', {
    staticClass: "modal-text__item"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };