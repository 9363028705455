import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import ReportMixin from "@/mixins/Report";
import RangeCalendar from "@/components/RangeCalendar.vue";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
import MainTitle from "@/components/MainTitle.vue";
import AppTabs from "@/components/AppTabs.vue";
import SwitchInput from "@/components/SwitchInput.vue";
import LoadingSpinner from "@/components/Loading/Spinner";
import ProductsTable from "@/components/PriceVar/ProductsTable.vue";
import ModalPriceVarImport from "@/components/modals/ModalPriceVarImport.vue";
import ModalPriceVarRemoveItem from "@/components/modals/ModalPriceVarRemoveItem.vue";
import ModalImportRrc from "@/components/modals/ModalImportRrc.vue";
import ModalImportRrcResult from "@/components/modals/ModalImportRrcResult.vue";
import ModalRrcNoFresh from "@/components/modals/ModalRrcNoFresh.vue";
import ModalLimitReachedRrc from "@/components/modals/access/ModalLimitReachedRrc.vue";

//import { dayjs } from "@/utils/dates";

const tableComponents = {
  productPriceVar: ProductsTable,
  productPriceVarPercent: ProductsTable,
  productPriceVarRub: ProductsTable

  // productPriceVar: ProductsTable,
  // productPriceVarVal: ProductsTable,

  //productPriceVarDown: ProductsTable,
  //productPriceVarUp: ProductsTable,

  //productPriceVar: ProductPriceVarDetailTable,
};
export default {
  mixins: [ReportMixin],
  meta() {
    var _this$list;
    const name = (_this$list = this.list) === null || _this$list === void 0 ? void 0 : _this$list.title;
    return {
      title: name ? `Список "${name}"` : undefined
    };
  },
  data() {
    return {
      list: null,
      showModalEditLists: false,
      tabs_tables_all: [
      // product
      {
        id: "productPriceVar",
        title: ""
      }, {
        id: "productPriceVarPercent",
        title: ""
      }, {
        id: "productPriceVarRub",
        title: ""
      }

      /*
      {
          id: "productPriceVarDetail",
          title: "Детализация pNN",
      },
      */],
      tabs_tables_model: null,
      progress: 0,
      progressTimer: null,
      loadingPriceVarFresh: false,
      qWithPriceFresh: 1,
      totNotPidSku: 0,
      // новых sku (не было в БД)
      totUpdateSku: 0,
      // - обновлены
      totAddListSku: 0,
      // - добавлены в список
      totNewSku_End: 0,
      // не удалось добавить
      not_found_sku: [],
      totSkuNotPrice: -1,
      totSkuNotPriceTryTask: -1,
      //priceVarRefresh_res: "",
      limitRrc: null
    };
  },
  async mounted() {
    await this.loadRrcLimits();
    await this.loadList();
    this.$nextTick(function () {
      //console.log('260: this.list=', this.list)

      // Код, который будет запущен только после отрисовки всех представлений
      // новый | пустой list
      if (this.list.count == 0) {
        this.$refs.ModalImportRrc.open({});
      }
      // не было обновлений цен
      else if (this.list.qPriceFreshed == 0) {
        this.$refs.ModalRrcNoFresh.open({});
      }
    });
  },
  methods: {
    // override mixin marketplace getter
    getMP() {
      var _this$list2;
      return (_this$list2 = this.list) === null || _this$list2 === void 0 ? void 0 : _this$list2.mp;
    },
    getTableId() {
      return this._uid;
    },
    async loadRrcLimits() {
      this.limitRrc = await this.$store.dispatch("priceVar/getRrcLimit", {
        fake: this.$route.params.id
      });
    },
    async loadList() {
      this.list = await this.$store.dispatch("priceVar/getPriceVar", {
        id: this.$route.params.id
        //qWithPriceFresh: this.qWithPriceFresh,
      });

      //console.log("263: this.list=", this.list);
    },
    getTableComponent(id) {
      /*
      document.getElementById("PriceVarImportBtn").style.display = [
          "productPriceVar",
          "productPriceVarVal",
          //"productPriceVarDown",
          //"productPriceVarUp",
           //"productPriceVarDetail",
      ].includes(id)
          ? "block"
          : "none";
      */

      return tableComponents[id];
    },
    deselect() {
      this.table_data.data.forEach(el => {
        el.checked = false;
      });
      this.select_all = false;
    },
    deleteItem() {
      let data = [];
      this.table_data.data.forEach(item => {
        if (!item.checked) {
          data.push(item);
        }
      });
      this.table_data.data = [...data];
    },
    onImportClick() {
      this.$refs.modalPriceVarImport.open({
        item: this.list
      });
    },
    onImportComplete() {
      this.$refs.table.refresh();
    },
    onRemove({
      items,
      clean,
      refresh
    }) {
      this.$refs.modalPriceVarRemoveItem.open({
        list: this.list,
        items,
        onComplete() {
          clean();
          refresh();
        }
      });
    },
    async onSelectAction_RrcPriceFresh({
      items,
      clean
      //, refresh
    }) {
      let aSku = [];
      items.forEach(t => {
        aSku.push(t.sku);
      });
      //console.log("aSku=", aSku);

      await this.createPriceVarRefreshRequest(aSku);
      clean();
      //refresh();
    },
    onImportRrcButton() {
      //console.log('onImportRRCButton()');
      this.$refs.ModalImportRrc.open({});
    },
    onComplete_ModalImportRrc(res) {
      //console.log("370: res=", res);

      this.totAddListSku = res === null || res === void 0 ? void 0 : res.totAddListSku; // - добавлены в список
      this.totUpdateSku = res === null || res === void 0 ? void 0 : res.totUpdateSku; // - обновлены

      this.totNotPidSku = res === null || res === void 0 ? void 0 : res.totNotPidSku; // новых sku (не было в БД)
      this.totNewSku_End = res === null || res === void 0 ? void 0 : res.totNewSku_End; // не удалось добавить
      this.not_found_sku = res === null || res === void 0 ? void 0 : res.not_found_sku;
      let res2 = {
        ...res
      };
      //console.log("382: res2=", res2);

      //console.log("382: this.refs.ModalImportRrcResult=", this.$refs.ModalImportRrcResult);

      this.$refs.ModalImportRrcResult.open({
        res2
      });

      //this.$refs.table.refresh();
      //this.refreshKey++;
    },
    async onComplete_ModalImportRrcResult() {
      this.$refs.table.refresh();
      this.refreshKey++;

      //еще раз загрузим
      let list = await this.$store.dispatch("priceVar/getPriceVar", {
        id: this.$route.params.id
      });
      if (list.count > 0 && list.qPriceFreshed == 0) {
        this.$refs.ModalRrcNoFresh.open({});
      }
    },
    async createPriceVarRefreshRequest_btn() {
      try {
        await this.createPriceVarRefreshRequest([]);
      } catch (ex) {
        console.log('409: ERROR: ex=', ex);
      }
    },
    async createPriceVarRefreshRequest(aSku) {
      //console.log("1: aSku=", aSku);

      this.loadingPriceVarFresh = true;
      this.progress = 0;
      //this.hash = this.list.id+"/"+dayjs().format("YYYY-MM-DDTHH:mm:ss")

      ////this.qWithPriceFresh = 1;

      let res = null;
      try {
        res = await this.$store.dispatch("priceVar/createPriceVarRefreshRequest", {
          //hash: this.hash,
          parameters: this.reportSettings,
          aSku
        });
        //console.log("createPriceVarRefreshRequest.res=", res);
      } catch (e) {
        var _e$response;
        this.loadingPriceVarFresh = false;
        this.progress = 100;
        if (((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 409) {
          //alert("Превышена квота обновления цен")
          this.$modal.open(() => import("@/components/modals/access/ModalLimitReachedRrc"), {
            limit: this.limitRrc.limit
          });
        }
        return;
      }
      this.hash = res.hash;
      this.totSkuNotPrice = res.totSkuNotPrice;
      //console.log("this.totSkuNotPrice=", this.totSkuNotPrice);

      this.progressTimer = setInterval(async () => {
        //if (this.progress < 100) {
        if (this.totSkuNotPrice > 0) {
          await this.loadProgress(this.hash);
        } else {
          //console.log("1: clearInterval()");

          clearInterval(this.progressTimer);
          this.loadingPriceVarFresh = false;

          //console.log('this=', this)

          this.loadRrcLimits();
          this.qWithPriceFresh = 1;
          this.$refs.table.refresh();
        }
      }, 5000);
    },
    async loadProgress(hash) {
      let res = null;
      res = await this.$store.dispatch("priceVar/getPriceVarRefreshProgress", {
        hash
      });
      //console.log("getPriceVarRefreshProgress.res=", res);

      //this.progress = Number(progress?.data.progress);
      //this.progress = Number(res?.progress);

      this.totSkuNotPrice = res.totSkuNotPrice;
    }
  },
  computed: {
    tabs_tables() {
      return this.tabs_tables_all.filter(tab => {
        // alert('4: tab.id='+tab.id)
        // alert('5: this.list='+JSON.stringify(this.list))

        //if( tab.id === this.list.monitor ){
        if (tab.id.indexOf(this.list.monitor) === 0) {
          //alert('6: true')
          return true;
        }

        //alert('7: false')
        return false;

        // if( this.list.entity==='brand'){
        //     return true;
        // }
      });
    },
    fileName() {
      return `${this.routeParams.mp.toUpperCase()} Список ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    selectedItems() {
      if (this.table_data && this.table_data.data) {
        let res = [];
        this.table_data.data.forEach(el => {
          if (el.checked) res.push(el);
        });
        return res;
      } else {
        return [];
      }
    },
    listKey() {
      return `${this.$route.params.id}`;
    },
    reportSettings() {
      //alert('2: this.list='+JSON.stringify(this.list))

      if (!this.calendar || !this.list) {
        return null;
      }

      //alert('3: this.list='+JSON.stringify(this.list))

      return {
        id: this.$route.params.id,
        date: this.calendar[0],
        date2: this.calendar[1],
        fbs: this.fbs,
        //monitor: m,
        qWithPriceFresh: this.qWithPriceFresh
      };
    },
    routeParams() {
      var _this$list3;
      return {
        mp: (_this$list3 = this.list) === null || _this$list3 === void 0 ? void 0 : _this$list3.mp
      };
    }
  },
  watch: {
    listKey() {
      this.loadList();
    }
  },
  components: {
    RangeCalendar,
    DataTableActions,
    MainTitle,
    AppTabs,
    LoadingSpinner,
    SwitchInput,
    ProductsTable,
    ModalPriceVarRemoveItem,
    ModalPriceVarImport,
    ModalImportRrc,
    ModalImportRrcResult,
    ModalRrcNoFresh,
    ModalLimitReachedRrc
  }
};