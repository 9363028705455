import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import { read, utils } from "xlsx";
import Tooltip from "@/components/Tooltip";
export default {
  components: {
    Tooltip
  },
  name: "ImportBtn",
  emits: ["imported"],
  props: {
    tooltip: {
      required: false
    },
    tooltipClasses: {
      default: "top right",
      required: false
    },
    btnText: {
      type: String,
      default: "Загрузить из XLSX"
    }
  },
  methods: {
    handleFileSelect(event) {
      const files = event.target.files;
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = read(data, {
          type: "binary"
        });
        workbook.SheetNames.forEach(sheetName => {
          let XL_row_object = utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          let rows = [];

          /*
          rows.push(Object.keys(XL_row_object[0])[0]);
          rows = [
              rows,
              ...XL_row_object.map((row) => Object.values(row)[0]),
          ];
          */

          rows.push(Object.keys(XL_row_object[0])[0]);
          rows.push(Object.keys(XL_row_object[0])[1]);
          rows = [rows, ...XL_row_object.map(row => [Object.values(row)[0], Object.values(row)[1]])];
          this.$emit("imported", rows);
        });
      };
      reader.onerror = function (ex) {// eslint-disable-line
        //console.log(ex);
      };
      reader.readAsBinaryString(files[0]);
      this.$refs.file.value = "";
    }
  }
};