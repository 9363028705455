var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Результат",
      "width": 420,
      "actions": _vm.actions
    }
  }, [_c('div', {
    staticClass: "modal-text"
  }, [_c('div', {
    staticClass: "modal-text__item"
  }, [_c('br'), _vm.result && _vm.result.totAddListSku > 0 ? _c('span', [_vm._v(" " + _vm._s(_vm.pluralSkuAdd) + " ")]) : _vm._e(), _vm.result && _vm.result.totUpdateSku > 0 ? _c('span', [_vm._v(" " + _vm._s(_vm.pluralSkuUpdate) + " ")]) : _vm._e()]), _vm.result && _vm.result.not_found_sku && _vm.result.not_found_sku.length > 0 ? _c('div', {
    staticClass: "modal-text__item"
  }, [_c('span', [_vm._v(_vm._s(_vm.pluralProductsNotFound))])]) : _vm._e(), _vm.result && _vm.result.not_found_sku && _vm.result.not_found_sku.length > 0 ? _c('div', {
    staticClass: "modal-text__item"
  }, [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.downloadList
    }
  }, [_vm._v("скачать список необработанных sku")])]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };